











































import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import { AddPartnerShop,UpdatePartnerShop } from '@/api'
import { Message } from 'element-ui'
import { ElForm } from 'element-ui/types/form'
import { VImageUpload } from '@/components/upload'
import constant from '@/utils/constant'

@Component({
  components: {
    VImageUpload,
  },
})
export default class EditPartnerShopDialog extends Vue {
  private constant = constant
  private show = false
  private data: any = {partnerChannel:'ysb',shopConfig:{baseUrl:'http://yst-api.ysbang.cn/misc-ysb/'}}
  private requiredRule = { required: true, message: '不能为空', trigger: 'blur' }
  private rules = {
    partnerChannel: [this.requiredRule],
    shopName: [this.requiredRule],
    billTitle: [this.requiredRule],
    billPhone: [this.requiredRule],
    appid: [this.requiredRule],
    'shopConfig.baseUrl': [this.requiredRule],
    'shopConfig.authCode': [this.requiredRule],
    'shopConfig.appSecret': [this.requiredRule],
    'shopConfig.dev': [this.requiredRule],
  }

  @Emit('close')
  private close() {
    const f = this.$refs.form as ElForm
    f.resetFields()
    this.show = false
    this.data ={partnerChannel:'ysb',shopConfig:{baseUrl:'http://yst-api.ysbang.cn/misc-ysb/'}}
  }
  public open(param: any) {
    this.show = true
    if(param){
      this.data = {...param}
      this.data.shopConfig = JSON.parse(this.data.shopConfig)
    } else {
      // this.data.fileListTemp = []
    }
  }

  public async save() {
    console.log(this.data)
    const f = this.$refs.form as ElForm
    f.validate(async (success: boolean) => {
      if (!success) {
        return
      }
      const param = {...this.data}
      param.shopConfig = JSON.stringify(param.shopConfig)
      if(!param.id ){
        await AddPartnerShop(param)
      } else {
        await UpdatePartnerShop(param)
      }
      Message.success('保存成功')
      this.close()
    })
  }

  private psChange(e){
    console.log(e)
    if(e == 'ysb'){
      this.data.shopConfig.baseUrl = "http://yst-api.ysbang.cn/misc-ysb/"
    }else if(e == 'YHYC'){
      this.data.shopConfig.baseUrl = "http://api.yaoex.com/"
    }else if(e == 'YBM'){
      this.data.shopConfig.baseUrl = "https://h-api.ybm100.com"
    }else if(e == 'YJJ'){
      this.data.shopConfig.baseUrl = "https://open-api.yyjzt.com"
      // this.data.shopConfig.dev = "false"
    }
  }
}
