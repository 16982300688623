
// 常量
const constan = {
  Role: [
    { label: '主账号', value: 1 },
    { label: '供应商', value: 2 },
    { label: '拿货人员', value: 3 },
    { label: '平台运营', value: 4 },
    { label: '开单员', value: 5 },
  ],
  PlatformRole: [
    { label: '主账号', value: 1 },
    { label: '拿货人员', value: 3 },
    { label: '平台运营', value: 4 },
    { label: '开单员', value: 5 },
  ],
 
  PlatformStatus: [
    { label: '禁用', value: 0 },
    { label: '启用', value: 1 },
  ],

  BusiType: [
    { label: '保健食品', value: 1 },
    { label: '医疗器械', value: 2 },
    { label: '食品', value: 3 },
    { label: '消杀类产品', value: 4 },
    { label: '日化类产品', value: 5 },
  ],

  BatchType: [
    { label: '所有批次', value: -1 },
    { label: '有库存批次', value: 0 },
  ],

  DrugApptType: [
    { label: '草稿', value: 0 },
    { label: '待审核', value: 1 },
    { label: '审核通过', value: 2 },
    { label: '审核拒绝', value: 3 },
    { label: '已下架', value: 4 },
  ],
  DrugApptTypeTag: [
    { label: '', value: 0 },
    { label: 'primary', value: 1 },
    { label: 'success', value: 2 },
    { label: 'danger', value: 3 },
    { label: 'danger', value: 4 },
  ],
  DrugApptType1: [
    { label: '审核通过', value: 2 },
    { label: '审核拒绝', value: 3 },
  ],
  DrugBatchLogType: [
    { label: '入库', value: 0 },
    { label: '出库', value: 1 },
  ],
  OrderStatus: [
    { label: '待开户', value: 0 },
    { label: '正在开单', value: 1 },
    { label: '已发货', value: 2 },
    { label: '已发货', value: 3 },
    { label: '已发货', value: 4 },
    { label: '订单退款', value: 5 },
    { label: '已完成', value: 6 },
    { label: '分拣中', value: 7 },
  ],
  InvoiceType: [
    { label: '缺失', value: 0 },
    { label: '普通发票', value: 1 },
    { label: '专用发票', value: 2 },
  ],
  IsEInvoice: [
    { label: '否', value: 0 },
    { label: '是', value: 1 },
  ],
  SupplierConfirm: [
    { label: '待确认', value: 0 },
    { label: '已确认', value: 1 },
    { label: '已退款', value: 2 },
  ],
  SupplierConfirm2: [
    { label: '未出库', value: 0 },
    { label: '已出库', value: 1 },
    { label: '已退款', value: 2 },
  ],
  ShopStatus: [
    { label: '待配置', value: -1 },
    { label: '正常', value: 0 },
    { label: '禁用', value: 1 },
    { label: '下载同步中', value: 2 },
    { label: '下载同步失败', value: 3 },
    { label: '上传商品中', value: 4 },
    { label: '上传商品失败', value: 5 },
  ],
  ShopStatusForChange: [
    { label: '正常', value: 0 },
    { label: '禁用', value: 1 },
  ],
  PartnerChannel: [
    { label: '药师帮', value: 'ysb' },
    { label: '1号药城', value: 'YHYC' },
    { label: '药帮忙', value: 'YBM' },
    { label: '药久久', value: 'YJJ' },
  ],
  LogisticsList: [
    { label: '韵达快递', value: '韵达快递' },
    { label: '顺丰快递', value: '顺丰快递' },
    { label: '申通快递', value: '申通快递' },
    { label: '京东快递', value: '京东快递' },
    { label: '中通快运', value: '中通快运' },
    { label: '中通速递', value: '中通速递' },
  ],
  RefundReason: [
    { label: '药品近效期', value: 0 },
    { label: '药品缺货', value: 1 },
    { label: '退运费', value: 10 },
    { label: '商品质量问题', value: 11 },
    { label: '药店证照问题', value: 2 },
    { label: '其他', value: 20 },
    { label: '未到达起送金额', value: 3 },
    { label: '发票相关-无票', value: 31 },
    { label: '发票相关-错票', value: 32 },
    { label: '发票相关-申请专票', value: 33 },
    { label: '发票相关-发票其它', value: 34 },
    { label: '下错单', value: 4 },
    { label: '资质相关', value: 41 },
    { label: '商品压损', value: 5 },
    { label: '订单商品拍错（商品/规格/厂家等）', value: 51 },
    { label: '我不想要了', value: 52 },
    { label: '药店/地址/电话/收件人信息填写错误', value: 53},
    { label: '没用/少用优惠', value: 54 },
    { label: '商品缺货', value: 55},
    { label: '批号不符', value: 6 },
    { label: '发货少货', value: 7 },
    { label: '商家发错货', value: 8 },
    { label: '超出药店经营范围', value: 9 },
    { label: '拍错/多拍/不想要', value: 68 },
  ],
  RefundStatus: [
    { label: '待审核', value: 0 },
    { label: '退款被拒', value: 9 },
    { label: '退款完成', value: 4 }
  ],
  RefundType: [
    { label: '部分腿款', value: 0 },
    { label: '整单退款', value: 1 },
  ],
  SalesType: [
    { label: '销售', value: 0 },
    { label: '退款', value: 1 },
  ],
  SuppliyPriceAuditStatus: [
    { label: '待审核', value: 0 },
    { label: '审核通过', value: 1 },
    { label: '审核失败', value: 2 },
  ],
 
  // banner支持的客户端
  BannerPlatform: [{ label: '微信小程序', value: 0 }],
  // banner展示位置
  BannerChannelId: [
    { label: '首页', value: 0 },
    { label: '待定', value: 1 },
  ],
  // banner跳转方式
  BannerJumpPage: [
    { label: '小程序内部', value: 0 },
    { label: 'H5', value: 1 },
  ],
  // 导航栏展示位置
  NavigationChannelId: [
    { label: '首页', value: 0 },
    { label: '我的-活动中心', value: 1 },
    { label: '待定', value: 999 },
  ],



}

export default constan
